import { HostService } from './host.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Avis } from '../avis';
import { IAvis } from '../Iavis';

@Injectable({
  providedIn: 'root'
})
export class AvisService {
  headers: HttpHeaders;
  constructor(private _http:HttpClient, private hostservice:HostService) {
    const token = localStorage.getItem('token');
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
   }
  host = this.hostservice.myhost;
  //----------------all avis-------------------
  _urlAll = this.host+"api/avisall";
  getAllAvisCP(){
    return this._http.get<IAvis[]>(this._urlAll);
  }
  //----------------Actualité-------------------
  _url = this.host+"api/avis";
  getAllAvis(){
    return this._http.get<IAvis[]>(this._url);
  }
  //----------------Licence-------------------
  _url2 = this.host+"api/avisLic";
  getAllAvisLic(){
    return this._http.get<IAvis[]>(this._url2);
  }
  //----------------Master-------------------
  _url3 = this.host+"api/avisMas";
  getAllAvisMas(){
    return this._http.get<IAvis[]>(this._url3);
  }
  //----------------Actualité-------------------
  _url4 = this.host+"api/avisDoc";
  getAllAvisDoc(){
    return this._http.get<IAvis[]>(this._url4);
  }
  //---------------------------------
  _urlavis = this.host+"api/avis/";
  getAvis(id:number){
    return this._http.get<Avis>(this._urlavis+id);
  }
  //-------------------Ajout-------------------
  avisUrl = this.host+"api/avis";
  setAvis(av:Avis){
    return this._http.post(this.avisUrl,av,{ headers: this.headers });
  }
  //----------------------uplodImageAvis---------------
  urlup =  this.host+"api/upload";
  uploadAvisImage(fd:FormData){
    return this._http.post(this.urlup,fd,{ headers: this.headers });
  }
  //----------------------updateUplodImageAvis---------------
  urlupd =  this.host+"api/updateupload";
  updateUploadAvisImage(fd:FormData){
    return this._http.post(this.urlupd,fd,{ headers: this.headers });
  }
  //-------------------Suppression-------------------
  delAvisUrl = this.host+"api/avis/";
  deletAvis(id:number){
    return this._http.delete(this.delAvisUrl+id,{ headers: this.headers });
  }
  //-------------------Update-------------------
  updateAvisUrl = this.host+"api/avis/";
  updateAvis(id:number,av:Avis){
    return this._http.put(this.updateAvisUrl+id,av,{ headers: this.headers });
  }
}
