<app-menu-site></app-menu-site>
<slides> </slides>
<div class="container-fluid">
<app-boutons></app-boutons>
<div class="row">
  <!----------------Part gauche-->
  <div class="col-lg-10 col-xs-12">
    <router-outlet></router-outlet>
    <app-video></app-video>
  </div>
<!---------------Partie droite-->
    <div class="col-lg-2 col-xs-12">
      <defilPub></defilPub>
      <app-liens></app-liens>
      <app-statistics></app-statistics>
    </div>
<!---------------Fin Partie droite-->
</div>
</div>

<app-footer></app-footer>
<ngx-scrolltop backgroundColor="#0052D4"></ngx-scrolltop>
