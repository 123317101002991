import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SlideService } from 'src/app/services/slide.service';
import { Slide } from 'src/app/slide';

@Component({
  selector: 'app-cp-slide',
  templateUrl: './cp-slide.component.html',
  styleUrls: ['./cp-slide.component.css']
})
export class CpSlideComponent implements OnInit {
slide = new Slide();
photoname="Choisir une image de taille 1450*300";
verou=false;
srcslide:string;
  constructor(private ss:SlideService,
              private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.srcslide = this.ss.host+"FLMLARA/storage/app/public/slides/";
    //this.srcslide = this.ss.host+"storage/slides/";
    this.mesSlides();
  }
 //------------------------------------
selectedFile = null;
onFileSelected(event){
  this.selectedFile = event.target.files[0];
  this.photoname = this.selectedFile.name;
  this.verou = true;
  //console.log(this.selectedFile);
}
//-----------------------------------
addSlide(){
  const nomSlide = new Date().getTime()+'.jpg';
  this.slide.image = nomSlide;
      this.ss.setSlide(this.slide).subscribe(
        () => {  this.mesSlides(); },
        err => console.error(err),

      );
if(this.selectedFile!=null){
//---------envoi de slide
  let fd = new FormData();
  fd.append('nomSlide',nomSlide);
  fd.append('imageSlide',this.selectedFile,this.selectedFile.name);

  this.ss.uploadSlide(fd).subscribe(
    //res => console.log(res),
    err => console.error(err)
  );
  this.selectedFile = null; // pour vider après l'envoie du fichier
  this.verou = false; //verrouiller le boutton d'ajout
 }
}
//-----------------------------------------------
allslides:Slide[];
mesSlides(){
  this.ss.getAllSlides().subscribe(
    data => {this.allslides = data;
    this.allslides.map(el => {el.titre = this.sanitizer.bypassSecurityTrustHtml(el.titre)})
    //titre doit être de type any
    // bypassSecurityTrustHtml pour assurer l'insertion des style html
    },
    err => console.error(err),
    //()=>console.log('ok slide root')
  );
}
//-------------------------------------------
//------------------------------------
idDelete:number;
getIdToDelete(id:number){
this.idDelete = id;
}
//-----------------------------------
supprimerSlide(){
  this.ss.deletSlide(this.idDelete).subscribe(
    () => { this.mesSlides();},
    err => console.error(err),

  );

}


//---------------------------------------------------
}
