import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Publication } from '../publication';
import { HostService } from './host.service';

@Injectable({
  providedIn: 'root'
})
export class PublicationService {

  headers: HttpHeaders;
  constructor(private _http:HttpClient, private hostservice:HostService) {
    const token = localStorage.getItem('token');
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
   }
   host = this.hostservice.myhost;
   //----------------------------------------------------
//-----------------------------------------
_url = this.host+"api/publications";
getAllPubs(){
  return this._http.get<Publication[]>(this._url);
}
//-----------------------------------------
//-------------------add publication-------------------
slideUrl = this.host+"api/publications";
setPublication(pub:Publication){
  return this._http.post(this.slideUrl,pub,{ headers: this.headers });//,{ headers: this.headers }
}

//----------------------uplodImagePub---------------
urlup =  this.host+"api/uploadpub";
uploadPub(fd:FormData){
  return this._http.post(this.urlup,fd,{ headers: this.headers });//
}
//-----------------------------------------
 //-------------------Suppression-------------------
 delpub = this.host+"api/publications/";
 deletPub(id:number){
   return this._http.delete(this.delpub+id,{ headers: this.headers });//
 }
  //-----------------------------------------------
}
