<cpmenu></cpmenu>
<div class="container">
  <form style="background-image: url('assets/img/bg.jpg'); padding: 10px;">

    <div class="form-group">
      <label class="h3">Ajouter Lien Vidéo</label>
      <p>Format embed ex: https://www.youtube.com/embed/vAYAwtyI9wo</p>
      <input type="text" name="lien" class="form-control" [(ngModel)]="video.lien">
      </div>


<div class="form-group col-6 offset-3">
  <button  class="btn btn-info col-12 " (click)="addVideo()">Ajouter</button>
</div>
  </form>

  <div class="row" *ngFor="let v of allVideo">
    <div class="col-8">

      <iframe  [src]="v.lien" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    </div>
    <div class="col-4">

      <button data-toggle="modal" [attr.data-target]="'#suppConfirm' + v.id"  class="btn btn-danger"><i class="fa fa-trash-o fa-2x" aria-hidden="true"></i></button>
    </div>

    <!---------------Modal suppression------------>
  <!-- The Modal -->
  <div class="modal" id="suppConfirm{{v.id}}">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Suppression</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Voulez-vous vraiment supprimer cette vidéo?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="supprimerVideo(v.id)" data-dismiss="modal">Ok</button>
          <button type="button" class="btn btn-secondary"  data-dismiss="modal">Annuler</button>
        </div>
      </div>
    </div>
  </div>
  <!---------------Fin modal supp-------------->
  </div>



</div>
