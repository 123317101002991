import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IAvis } from 'src/app/Iavis';
import { AvisService } from 'src/app/services/avis.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

hideSpinner = true;
  mypagination={
    itemsPerPage: 2, //initialisation
    currentPage: 1,
    totalItems:20 //initialisation
  }

  allAvis:IAvis[];
  srcimg:string;

  constructor(private as:AvisService,
     private sanitizer: DomSanitizer,
     private router:Router,
     private ps:PaginationService,
     private spinner: NgxSpinnerService) {
    this.allAvis = new Array<IAvis>();
  }

  ngOnInit(): void {
    /** spinner starts on init */
  this.spinner.show();
  //-------------------------------------------
    this.srcimg = this.as.host+"FLMLARA/storage/app/public/avis/";
    //this.srcimg = this.as.host+"storage/avis/"; //en local
    this.mesAvis();
    this.ps.getNumberPages().subscribe(
      res => this.mypagination.itemsPerPage = res.nombre_pages
    );

  }
//------------------------------------

mesAvis(){
  this.as.getAllAvis().subscribe(
    data => {
      this.allAvis = data;
      this.mypagination.totalItems= data.length;
      this.allAvis.map(el => {el.msg = this.sanitizer.bypassSecurityTrustHtml(el.msg)})
      // bypassSecurityTrustHtml pour assurer l'insertion des style html
      this.hideSpinner = false;
    },
    err => console.error(err),
    ()=>console.log('ok')
  )
}



//------------Social media-----------------
fblink:any;
fb(){
  let lienDeMaPage = document.location.href;
  //let lienDeMaPage="http://flshm.ma/";//http://flshm.univh2c.ma/";
  console.log(lienDeMaPage);
  this.router.navigate(["/"]).then(
    result=>{window.location.href = 'https://www.facebook.com/sharer.php?caption=ayad&description=ayadhabib&u='+lienDeMaPage;});
}

lnkd(titre:string){
  let lienDeMaPage = document.location.href;
  console.log(lienDeMaPage);

  this.router.navigate(["/"]).then(
    result=>{window.location.href = 'https://www.linkedin.com/shareArticle?url='+lienDeMaPage+'&title=$'+titre;});

}

wtsp(titre:string){
  let lienDeMaPage = document.location.href;
  console.log(lienDeMaPage);
  this.router.navigate(["/"]).then(
    result=>{window.location.href = 'https://wa.me/?text='+lienDeMaPage+'&title=$'+titre;});
}

//--------------------------------------
}
