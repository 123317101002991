<cpmenu></cpmenu>
<div class="container">
  <form style="background-image: url('assets/img/bg.jpg'); padding: 10px;">

    <div class="form-group">
      <label class="h3">Nombre d'étudiants</label>
      <input type="number" class="form-control" name="nbr" [(ngModel)]="stat.etudiants">
    </div>
    <div class="form-group">
      <label class="h3">Nombre de profs</label>
      <input type="number" class="form-control" name="nbr2" [(ngModel)]="stat.profs">
    </div>
    <div class="form-group">
      <label class="h3">Nombre d'administratifs</label>
      <input type="number" class="form-control" name="nbr3" [(ngModel)]="stat.administratifs">
    </div>
    <div class="form-group">
      <label class="h3">Nombre dd départements</label>
      <input type="number" class="form-control" name="nbr4" [(ngModel)]="stat.departements">
    </div>
    <div class="form-group">
      <label class="h3">Nombre de LF</label>
      <input type="number" class="form-control" name="nbr5" [(ngModel)]="stat.lf">
    </div>
    <div class="form-group">
      <label class="h3">Nombre de LP</label>
      <input type="number" class="form-control" name="nbr6" [(ngModel)]="stat.lp">
    </div>
    <div class="form-group">
      <label class="h3">Nombre de Masters</label>
      <input type="number" class="form-control" name="nbr7" [(ngModel)]="stat.master">
    </div>
    <div class="form-group">
      <label class="h3">Nombre de Master Spécialisés</label>
      <input type="number" class="form-control" name="nbr8" [(ngModel)]="stat.msp">
    </div>
    <div class="form-group">
      <label class="h3">Nombre de filières</label>
      <input type="number" class="form-control" name="nbr9" [(ngModel)]="stat.filieres">
    </div>
    <div class="form-group">
      <label class="h3">Nombre de Labos</label>
      <input type="number" class="form-control" name="nbr10" [(ngModel)]="stat.labos">
    </div>
    <div class="form-group">
      <label class="h3">Nombre de foramtions doctorales</label>
      <input type="number" class="form-control" name="nbr11" [(ngModel)]="stat.fd">
    </div>

<div class="form-group">

  <div class="form-group col-6 offset-3">
    <button class="btn btn-info col-12 " (click)="updateStat()">Modifier</button>
  </div>

</div>

  </form>
  <div class="row">
    <div class="col-12 h3 text-info">
      Statistique
    </div>
    <table class="table">
      <tr><th>etudiants</th>
        <th>profs</th>
        <th>administratifs</th>
        <th>departements</th>
        <th>lf</th>
        <th>lp</th>
        <th>master</th>
        <th>msp</th>
        <th>filieres</th>
        <th>labos</th>
        <th>fd</th></tr>
        <tr>
          <td>{{stat.etudiants}}</td>
          <td>{{stat.profs}}</td>
          <td>{{stat.administratifs}}</td>
          <td>{{stat.departements}}</td>
          <td>{{stat.lf}}</td>
          <td>{{stat.lp}}</td>
          <td>{{stat.master}}</td>
          <td>{{stat.msp}}</td>
          <td>{{stat.filieres}}</td>
          <td>{{stat.labos}}</td>
          <td>{{stat.fd}}</td>
        </tr>
    </table>
  </div>



  <!---------------Modal suppression------------>
  <!-- The Modal -->

  <!---------------Fin modal supp-------------->

</div>
