export class Statistique {
  etudiants: number;
  profs: number;
  administratifs: number;
  departements: number;
  lf: number;
  lp: number;
  master: number;
  msp: number;
  filieres: number;
  labos: number;
  fd: number;
}
