import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SlideService } from 'src/app/services/slide.service';
import { Slide } from 'src/app/slide';

@Component({
  selector: 'slides',
  templateUrl: './page-slides.component.html',
  styleUrls: ['./page-slides.component.css']
})
export class PageSlidesComponent implements OnInit {
srcslide:string;
  constructor(private ss:SlideService,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.srcslide = this.ss.host+"FLMLARA/storage/app/public/slides/";
    //this.srcslide = this.ss.host+"storage/slides/";
    this.mesSlides();
  }

  allslides:Slide[];
mesSlides(){
  this.ss.getAllSlides().subscribe(
    data => {this.allslides = data;
    this.allslides.map(el => {el.titre = this.sanitizer.bypassSecurityTrustHtml(el.titre)})
    //titre doit être de type any
    // bypassSecurityTrustHtml pour assurer l'insertion des style html
    },
    err => console.error(err),
    ()=>console.log('ok slide root')
  );
}

}
