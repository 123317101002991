import { HostService } from './host.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from './../user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private _http:HttpClient, private hostservice:HostService) { }
   host = this.hostservice.myhost;
 //-------------------Login--------------------
 url = this.host+"api/login";
 userLog(user:User){
   return this._http.post(this.url,user);
 }
//----------------------------------------------
isLoggedIn: Boolean = false;
signIn(){
     this.isLoggedIn = true;
     localStorage.setItem('isLoggedIn', String(this.isLoggedIn));
}
//---------------------signOut-----------------------
signOut(){
     this.isLoggedIn = false;
     localStorage.setItem('isLoggedIn', String(this.isLoggedIn));
}

}
