import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Video } from '../video';
import { HostService } from './host.service';

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  headers: HttpHeaders;
  constructor(private _http:HttpClient, private hostservice:HostService) {
    const token = localStorage.getItem('token');
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
   }
   host = this.hostservice.myhost;
//-----------------------------------------
_url = this.host+"api/videos";
getAllVideos(){
  return this._http.get<Video[]>(this._url);
}
//-------------------Ajout-------------------
_urladd = this.host+"api/videos";
setVideo(video:Video){
  return this._http.post(this._urladd,video,{ headers: this.headers });
}
//-------------------Suppression-------------------
urlDel = this.host+"api/videos/";
deletVideo(id:number){
  return this._http.delete(this.urlDel+id,{ headers: this.headers });
}
//-----------------------------------------


}
