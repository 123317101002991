<div class="row">
  <div class="col-lg-12">
    <table>
      <tr>
        <td><a href="" [routerLink]="['/home']"><img src="/assets/img/bt6.png" class="img-fluid"></a></td>
        <td><a href="" [routerLink]="['/licence']"><img src="/assets/img/bt7.png" class="img-fluid"></a></td>
        <td><a href="" [routerLink]="['/master']"><img src="/assets/img/bt8.png" class="img-fluid"></a></td>
        <td><a href="" [routerLink]="['/doctorat']"><img src="/assets/img/bt9.png" class="img-fluid"></a></td>
      </tr>
    </table>
  </div>
</div>
