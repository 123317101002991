import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-liens',
  templateUrl: './liens.component.html',
  styleUrls: ['./liens.component.css']
})
export class LiensComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
