<footer class="fo">
  <div class="row">
<div class="col-md-4">
  <ul class="no-bullets">
    <li class="h3">FLSHM</li>
    <li><strong>FACULTE DES LETTRES ET DES SCIENCES HUMAINES</strong></li>
    <li>AVENUE HASSAN II  B.P. 546 MOHAMMEDIA</li>
    <li><i class="fas fa-phone"></i> + 212 (0) 523 32 48 73/74</li>

  <div class="navbar-text social-media social-media-inline pull-left">
    <a style="font-size: 16pt; font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;">Suivez nous: </a>
    <a href="https://www.facebook.com/flshm.officielle" target="_blank"><i class="fa fa-facebook-square fa-2x"></i></a>
    <a href="#"  style="margin-left: 31px;" target="_blank"><i class="fa fa-twitter-square fa-2x"></i></a>
    <a href="https://www.youtube.com/channel/UCww7k3TDhwVFMTaPrgoWIvA"  style="margin-left: 31px;"><i class="fa fa-youtube-square fa-2x"></i></a>
  </div>
</ul>
</div>
<!------------------------------------------------------------->
<div class="col-md-4">
  <ul class="no-bullets">
    <li class="h3">Liens Importants</li>
    <li><a href="https://e-learning.univh2c.ma/" target="_blank">Plateforme E-learning</a></li>
    <li><a href="http://bouhout.ma/" target="_blank">Revue Bouhout</a></li>
    <li><a href="http://www.univh2c.ma/" target="_blank">Univeristé Hassan II de Casablanca</a></li>
    <li></li>
</ul>
</div>
<!------------------------------------------------------------->
<div class="col-md-4 text-center">
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1659.613313909794!2d-7.376425176252986!3d33.70308424551225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x849ff06389fa1f5d!2sFacult%C3%A9+des+Lettres+et+des+Sciences+Humaines+-+Mohammedia!5e0!3m2!1sen!2suk!4v1497403782132" height="260"></iframe>
</div>
</div>
<div class="col-12 text-center copyright">
  TOUS DROITS RÉSERVÉS &copy; {{y}}
</div>
</footer>
