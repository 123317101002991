<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1" class="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
  </ol>
  <div class="carousel-inner" role="listbox">

    <div class="carousel-item" *ngFor="let s of allslides; let i=index">
      <img class="d-block" src="{{srcslide}}{{s.image}}">
      <div class="carousel-caption d-none d-md-block">
        <h5 [innerHTML]="s.titre"></h5>
      </div>
    </div>

    <div class="carousel-item active"> <!-- active est obligatoire pour un seule slide -->
      <img class="d-block" src="/assets/img/s1.jpg" alt="Second slide">
      <div class="carousel-caption d-none d-md-block">
        <h5>FLSHM</h5>
      </div>
    </div>

  </div>
  <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <i class="now-ui-icons arrows-1_minimal-left"></i>
  </a>
  <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <i class="now-ui-icons arrows-1_minimal-right"></i>
  </a>
</div>
