import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CpGuard implements CanActivate {
  constructor(private auth:AuthService, private router:Router){}
  canActivate():boolean{
      if(this.auth.isLoggedIn){
        //console.log('guard:',this.auth.isLoggedIn);
        return true;
      }else{
        //console.log('guard:',this.auth.isLoggedIn);
        this.router.navigate(['/login']);
        return false;
      }
    }

}
