import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-boutons',
  templateUrl: './boutons.component.html',
  styleUrls: ['./boutons.component.css']
})
export class BoutonsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
