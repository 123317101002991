import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HostService {
//myhost = "http://127.0.0.1:8000/";
 //myhost = "https://sanctum.flshm.ma/";
 myhost = "http://flshm.univh2c.ma/FLMLARAVEL/";


  constructor() { }
}
