<cpmenu></cpmenu>
<div class="container">
  <form style="background-image: url('assets/img/bg.jpg'); padding: 10px;">
    <div class="form-row">
      <div class="col-10"></div>
      <div class="col-2"><button class="btn btn-danger" (click)="logout()"><i class="fa fa-sign-out fa-2x" aria-hidden="true"></i></button></div>
    </div>
    <div class="form-group">
      <label class="h3">Titre</label>
      <input type="text" class="form-control" name="titre" [(ngModel)]="avis.titre" placeholder="Titre">
    </div>
    <div class="form-row">
    <div class="form-group col-6">
      <label class="h3">Catégorie:</label>
      <select class="form-control" name="cat" [(ngModel)]="avis.categorie">
        <option value="1">Actualité</option>
        <option value="2">Licence</option>
        <option value="3">Master</option>
        <option value="4">Doctorat</option>

      </select>
    </div>
    <div class="form-group col-6">
      <label class="h3">Priorité:</label>
    <div class="form-check">
      <label class="form-check-label">
        <input class="form-check-input"  type="checkbox" name="prio" [(ngModel)]="avis.prio">
        <span class="form-check-sign" style="background: rgb(140, 77, 153);"></span>
        oui
      </label>
    </div>
    </div>
  </div>
    <div class="form-group">
      <label class="h3">Avis</label>
    <editor name="dd" [(ngModel)]="avis.msg"  apiKey="nxugqykf0aggo3yb2jkeack5ozx7fi7d0xwbgw6rjg9ygg97" [init]="{
      height: 200,
      menubar: ['table','insert'],
      plugins: [
        'advlist autolink lists link image charmap print',
        'preview anchor searchreplace visualblocks code',
        'fullscreen insertdatetime media table paste',
        'help wordcount','link','table'

      ],
      link_assume_external_targets: true,
      toolbar:
        'undo redo | formatselect | bold italic |fontsizeselect| \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent|forecolor backcolor |link| table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol|help'
    }"></editor>
      </div>

<div class="form-group">
  <div class="custom-file">
    <input type="file" (change)="onFileSelected($event)" class="custom-file-input" id="customFile" name="imageFile">
    <label class="custom-file-label" style="background: rgb(140, 77, 153); color: beige;" for="customFile">{{photoname}}</label>
  </div>
</div>

<div class="form-group">
  <label class="h3">Afficher l'image seulement:</label>
<div class="form-check">
  <label class="form-check-label">
    <input class="form-check-input" type="checkbox" name="onlyPic" [(ngModel)]="avis.image_only">
    <span class="form-check-sign" style="background: rgb(140, 77, 153);"></span>
    oui
  </label>
</div>
</div>

<div class="form-group col-6 offset-3">
  <button class="btn btn-info col-12 " (click)="addAvis()">Ajouter</button>
</div>
  </form>

<div class="row">
  <div class="col-lg-4 col-sm-12" *ngFor="let av of allAvis; let i=index" style="margin-bottom: 10px;">
    <div class="card h-100">
      <img class="card-img-top shape" src="assets/img/card.jpg" alt="Card image cap">
      <div class="card-body">
        <h4 class="card-title">{{av.date}}|{{av.titre}}</h4>
      <div *ngIf="av.image_only=='0'">
        <img *ngIf="av.image" src="{{srcimg}}{{av.image}}" class="img-thumbnail col-6">
        <div class="card-text" *ngIf="av.lang=='ar'" dir="rtl" [innerHTML]="av.msg"></div>
        <div class="card-text" *ngIf="av.lang=='fr'" dir="ltr" [innerHTML]="av.msg"></div>
      </div>
      <img *ngIf="av.image_only!='0'" src="{{srcimg}}{{av.image}}" class="img-thumbnail">
        <button data-toggle="modal" data-target="#suppConfirm" (click)="getIdToDelete(av.id)" class="btn btn-danger"><i class="fa fa-trash-o fa-2x" aria-hidden="true"></i></button>
        <button data-toggle="modal" data-target="#updata" (click)="showUpdateAvis(av.id)" class="btn btn-success"><i class="fa fa-edit fa-2x" aria-hidden="true"></i></button>
      </div>
    </div>
  </div>

</div>

<!--======================================-->

<!-- Modal -->
<div class="modal fade" id="updata" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modification Avis</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!--=================Formulaire=======================-->
        <form>
          <div class="form-group">
            <label class="h3">Date</label>
            <input type="text" class="form-control" value={{upAvis.date}} name="date" [(ngModel)]="upAvis.date">
          </div>
          <div class="form-group">
            <label class="h3">Titre</label>
            <input type="text" class="form-control" value={{upAvis.titre}} name="titre" [(ngModel)]="upAvis.titre">
          </div>
          <div class="form-row">
          <div class="form-group col-6">
            <label class="h3">Catégorie:</label>
            <select class="form-control" name="cat" [(ngModel)]="upAvis.categorie">
              <option value="1">Actualité</option>
              <option value="2">Licence</option>
              <option value="3">Master</option>
              <option value="4">Doctorat</option>

            </select>
          </div>
          <div class="form-group col-6">
            <label class="h3">Priorité:</label>
          <div class="form-check">
            <label class="form-check-label">
              <input class="form-check-input" type="checkbox" name="prio" [(ngModel)]="upAvis.prio">
              <span class="form-check-sign"></span>
              oui
            </label>
          </div>
          </div>
        </div>
          <div class="form-group">
            <label class="h3">Avis</label>
          <editor name="dd" [(ngModel)]="upAvis.msg"  apiKey="nxugqykf0aggo3yb2jkeack5ozx7fi7d0xwbgw6rjg9ygg97" [init]="{
            height: 200,
            menubar: ['table','insert'],
            plugins: [
              'advlist autolink lists link image charmap print',
              'preview anchor searchreplace visualblocks code',
              'fullscreen insertdatetime media table paste',
              'help wordcount','link','table'
            ],
            toolbar:
              'undo redo | formatselect | bold italic |fontsizeselect| \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent|forecolor backcolor |link| table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol|help'
          }"></editor>
            </div>

      <div class="form-group">
        <div class="custom-file">
          <input type="file" (change)="onFileSelectedUpdate($event)" class="custom-file-input" id="customFile" name="pic">
          <label class="custom-file-label btn-primary" for="customFile">{{photoname}}</label>
        </div>
      </div>
      <div class="form-group">
        <label class="h3">Affichier l'image seulement:</label>
      <div class="form-check">
        <label class="form-check-label">
          <input class="form-check-input" type="checkbox" name="onlyPic"   [(ngModel)]="upAvis.image_only">
          <span class="form-check-sign"></span>
          oui
        </label>
      </div>
      </div>


        </form>
        <!------------------------------------------------------>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
        <button type="button" (click)="modifAvis(upAvis.id)" class="btn btn-info" data-dismiss="modal">Modifier</button>
      </div>
    </div>
  </div>
</div>

<!--****************************************-->

 <!---------------Modal suppression------------>
  <!-- The Modal -->
  <div class="modal" id="suppConfirm">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Suppression</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Voulez-vous vraiment supprimer cet Avis?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="supprimerAvis()" data-dismiss="modal">Ok</button>
          <button type="button" class="btn btn-secondary"  data-dismiss="modal">Annuler</button>
        </div>
      </div>
    </div>
  </div>
  <!---------------Fin modal supp-------------->

</div>
