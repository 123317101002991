<div class="container mt-3">
  <div class="row">
    <div class=" col-8 offset-2">
      <div class="card" >
        <div class="card-header h3 bg-success cardheader">
          كتب الأستاذة خلود السباعي
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">الصحة والنوع الإجتماعي <a href="http://flshm.univh2c.ma/psycho/ref1.pdf"><span class="badge badge-primary">تحميل</span></a></li>
          <li class="list-group-item">اللأشخاص في وضعية الإعاقة <a href="http://flshm.univh2c.ma/psycho/ref2.pdf" ><span class="badge badge-primary">تحميل</span></a></li>
          <li class="list-group-item">المرأة والعنف <a href="http://flshm.univh2c.ma/psycho/ref3.pdf" ><span class="badge badge-primary">تحميل</span></a></li>
          <li class="list-group-item">وضعية الإعاقة في المغرب <a href="http://flshm.univh2c.ma/psycho/ref4.pdf"><span class="badge badge-primary">تحميل</span></a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
