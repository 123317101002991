import { Component, OnInit } from '@angular/core';
import { Publication } from 'src/app/publication';
import { PublicationService } from 'src/app/services/publication.service';

@Component({
  selector: 'defilPub',
  templateUrl: './publication.component.html',
  styleUrls: ['./publication.component.css']
})
export class PublicationComponent implements OnInit {

  constructor(private sp:PublicationService) { }
  srcpub:string="";
  ngOnInit(): void {
    this.srcpub = this.sp.host+"FLMLARA/storage/app/public/publications/";
    this.allTitlePublications();
  }

  //-----------------------------------------------
allpubs:Publication[];
allTitlePublications(){
  this.sp.getAllPubs().subscribe(
    data => this.allpubs = data,
    err => console.error(err),
    ()=>console.log('ok')
  );
}
//-------------------------------------------------

}
