import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Publication } from 'src/app/publication';
import { PublicationService } from 'src/app/services/publication.service';

@Component({
  selector: 'app-page-publications',
  templateUrl: './page-publications.component.html',
  styleUrls: ['./page-publications.component.css']
})
export class PagePublicationsComponent implements OnInit {
  srcpub:string="";
  constructor(private sp:PublicationService,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.srcpub = this.sp.host+"FLMLARA/storage/app/public/publications/";
    this.allPublications();
  }
  allpubs:Publication[];
  allPublications(){
    this.sp.getAllPubs().subscribe(
      data => {this.allpubs = data;
      this.allpubs.map(el => {el.description = this.sanitizer.bypassSecurityTrustHtml(el.description)})
      //description doit être de type any
      // bypassSecurityTrustHtml pour assurer l'insertion des style html
      },
      err => console.error(err),
      ()=>console.log('ok Publications')
    );
  }
  //-------------------------------------------------
}
