import { Component, OnInit } from '@angular/core';
import { StatService } from 'src/app/services/stat.service';
import { Statistique } from 'src/app/statistique';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {
stat = new Statistique();
stud:number=0;

  constructor(private ss:StatService) { }

  ngOnInit(): void {
    this.getStatistique()

  }

  //------------get Stat----------------
  getStatistique(){
    this.ss.getStat().subscribe(
      res => this.stat = res,
      err => console.error(err),
      () => console.log('ok')
    );
  }
  /*nbrst:any = setInterval(()=>{
    this.stud++;
    console.log("compteur",this.stud)
    if(this.stud==this.stat.etudiants){
      clearInterval(this.nbrst);
    }
  },1)*/

}
