<div class="container">
  <form style="background-image: url('assets/img/bg.jpg'); padding: 10px;">
    <div class="form-group">
      <label class="h3">Email</label>
      <input type="email" class="form-control" name="login" [(ngModel)]="user.email" placeholder="Login">
    </div>
    <div class="form-group">
      <label class="h3">Mot de passe</label>
      <input type="password" class="form-control" name="pwd" [(ngModel)]="user.password" placeholder="pwd">
    </div>

<div class="form-group col-6 offset-3">
  <button class="btn btn-info col-12 " (click)="LoginApp()">Se connecter</button>
</div>
  </form>

</div>
