import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utilisateur } from '../utilisateur';
import { HostService } from './host.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  headers: HttpHeaders;
  constructor(private _http:HttpClient, private hostservice:HostService) {
    const token = localStorage.getItem('token');
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
   }
  host = this.hostservice.myhost;
   //----------------all avis-------------------
url = this.host+"api/users";
getAllUsers(){
  return this._http.get<Utilisateur[]>(this.url);
}
//-------------------Ajout-------------------
url2 = this.host+"api/register";
setUtilisateur(user:Utilisateur){
  return this._http.post(this.url2,user,{ headers: this.headers });
}
//-------------------Suppression-------------------
url3 = this.host+"api/users/";
deletUser(id:number){
  return this._http.delete(this.url3+id,{ headers: this.headers });
}

}
