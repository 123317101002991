import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-site',
  templateUrl: './menu-site.component.html',
  styleUrls: ['./menu-site.component.css']
})
export class MenuSiteComponent implements OnInit {

  constructor() { }

  today:string;
  ngOnInit(): void {

    this.aujourdhui();
    setInterval( ()=>{
     this.aujourdhui();
    },1000);
  }

  aujourdhui(){
    let jour = ["Dimanche","Lundi","Mardi","Mercredi", "Jeudi","Vendredi","Samedi"];
    let month = ["janv",	"févr",	"mars",	"avr",	"mai"	,"juin", "juill",	"août"	,"sept",	"oct",	"nov",	"déc"];
      let dt = new Date();
      let mois = dt.getMonth();
      let j = dt.getDate();
      let d = dt.getUTCDay();
      let h = dt.getHours();
      let m = dt.getMinutes();
      let s = dt.getSeconds();
      this.today = jour[d]+" "+String(j)+"-"+month[mois]+", "+String(h)+":"+String(m)+":"+String(s);
  }

}
