import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Slide } from '../slide';
import { HostService } from './host.service';

@Injectable({
  providedIn: 'root'
})
export class SlideService {
  headers: HttpHeaders;
  constructor(private _http:HttpClient, private hostservice:HostService) {
    const token = localStorage.getItem('token');
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
   }
   host = this.hostservice.myhost;
   //-------------------Ajout-------------------
  slideUrl = this.host+"api/slides";
  setSlide(slide:Slide){
    return this._http.post(this.slideUrl,slide,{ headers: this.headers });
  }
  //----------------------------------
  //----------------------uplodImageAvis---------------
  urlup =  this.host+"api/uploadslide";
  uploadSlide(fd:FormData){
    return this._http.post(this.urlup,fd,{ headers: this.headers });
  }
  //-----------------------------------------
  _url = this.host+"api/slides";
  getAllSlides(){
    return this._http.get<Slide[]>(this._url);
  }
  //-----------------------------------------
  //-------------------Suppression-------------------
  delSlideUrl = this.host+"api/slides/";
  deletSlide(id:number){
    return this._http.delete(this.delSlideUrl+id,{ headers: this.headers });
  }
  //----------------------------------------------------
}
