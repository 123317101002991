import { NotificationService } from './../../services/notification.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  constructor(private ns: NotificationService) { }

  ngOnInit(): void {
  }

myNotification = {
  //token
  //edLxjVg3SUGrugk2OogmK8:APA91bEHhGr_0qk52O5f0UJA4Ao1lptRIJhj9uCUsK1Lkrxt6FX5QqSay6LutHRMXLO3DnLDRF33Q0iwajlrxqvwn06aqhME6FZLsARY3jRVB4C8nQe7XWFHSvVOhrt54denGA7d4wRO
  to : 'edLxjVg3SUGrugk2OogmK8:APA91bEHhGr_0qk52O5f0UJA4Ao1lptRIJhj9uCUsK1Lkrxt6FX5QqSay6LutHRMXLO3DnLDRF33Q0iwajlrxqvwn06aqhME6FZLsARY3jRVB4C8nQe7XWFHSvVOhrt54denGA7d4wRO',
  collapse_key : 'type_a',
  notification : {
      body : '', // text de la notification
      title: '' //  titre de la notification
  },
  data : {
      body : 'Body of Your Notification in Data',
      title: 'Title of Your Notification in Title',
      key_1 : 'Value for key_1',
      key_2 : 'Value for key_2'
  }
 }
 //-------------------------------------------
 envoyerNotification(){
  this.ns.sendNotification(this.myNotification).subscribe(
    //data => console.log(data),
    er => console.error(er),

  );
 }
 //---------------------------------------
}
