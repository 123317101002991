<cpmenu></cpmenu>
<div class="container">
  <form style="background-image: url('assets/img/bg.jpg'); padding: 10px;">

    <div class="form-group">
      <label class="h3">Nom</label>
      <input type="text" class="form-control" name="nom" [(ngModel)]="utilisateur.name">
    </div>
    <div class="form-group">
      <label class="h3">Email</label>
      <input type="email" class="form-control" name="email1" [(ngModel)]="utilisateur.email">
    </div>
    <div class="form-group">
      <label class="h3">Mot de passe</label>
      <input type="password" class="form-control" name="pwd" [(ngModel)]="utilisateur.password">
    </div>
    <div class="form-group">
      <label class="h3">Mot de passe - Confirmation</label>
      <input type="password" class="form-control" name="pwd2" [(ngModel)]="utilisateur.password_confirmation">
    </div>
 <div class="form-group">

  <div class="form-group col-6 offset-3">
    <button class="btn btn-info col-12 " (click)="addUser()">Ajouter</button>
  </div>

</div>
  </form>
  {{utilisateur|json}}
  <div class="row">
    <div class="col-12 h3 text-info">
      Utilisateurs
    </div>
    <table class="table">
      <thead>
      <tr><th>Nom</th><th>E-mail</th><th>Action</th></tr>
      </thead>
      <tbody>
      <tr *ngFor="let u of users">
        <td>{{u.name}}</td>
        <td>{{u.email}}</td>
        <td><button data-toggle="modal" [attr.data-target]="'#suppConfirm' + u.id" class="btn btn-danger"><i class="fa fa-trash-o fa-2x" aria-hidden="true"></i></button></td>
         <!---------------Modal suppression------------>
  <!-- The Modal -->
  <div class="modal" id="suppConfirm{{u.id}}">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Suppression</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Voulez-vous vraiment supprimer {{u.name}}?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="supprimerUser(u.id)" data-dismiss="modal">Ok</button>
          <button type="button" class="btn btn-secondary"  data-dismiss="modal">Annuler</button>
        </div>
      </div>
    </div>
  </div>
  <!---------------Fin modal supp-------------->
      </tr>
    </tbody>
    </table>
  </div>

  </div>
