<div class="container-fluid">
  <div class="row">
    <div id="carouselPublications" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#carouselPublications" data-slide-to="0"></li>
        <li data-target="#carouselPublications" data-slide-to="1" class="active"></li>
        <li data-target="#carouselPublications" data-slide-to="2"></li>
      </ol>
      <div class="carousel-inner" role="listbox">
        <div class="carousel-item" *ngFor="let p of allpubs; let i=index">
          <a href="/publications"><img class="d-block" src="{{srcpub}}{{p.image}}"></a>
          <div class="carousel-caption">
            <h5 class="flex-caption d-none d-md-block" [innerHTML]="p.titre"></h5>
          </div>
        </div>
        <div class="carousel-item active">
          <img class="d-block" src="assets/img/pub.jpg">
          <div class="carousel-caption d-none d-md-block">
            <h5></h5>
          </div>
        </div>

      </div>
      <a class="carousel-control-prev" href="#carouselPublications" role="button" data-slide="prev">
        <i class="now-ui-icons arrows-1_minimal-left"></i>
      </a>
      <a class="carousel-control-next" href="#carouselPublications" role="button" data-slide="next">
        <i class="now-ui-icons arrows-1_minimal-right"></i>
      </a>
    </div>
  </div>
</div>


