import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { VideoService } from 'src/app/services/video.service';
import { Video } from 'src/app/video';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {

  constructor(private vs:VideoService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.mesVideo();
  }
  //----------------------------------
allVideo:Video[];
  mesVideo(){
    this.vs.getAllVideos().subscribe(
      data => {this.allVideo = data;
      this.allVideo.map(el=>{el.lien = this.sanitizer.bypassSecurityTrustResourceUrl(el.lien)})},
      err => console.error(err),
      ()=>console.log('ok')
    )
  }
  //------------------------------------

}
