import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  headers: HttpHeaders;
  constructor(private _http:HttpClient) {
    const autorisation = 'key=AAAAsUNsvGE:APA91bEw7Azoo2AoQVjfvc9ivBWp3FRQBs3Sw-2ELgBSnq_qDi0R3WNfmg1WN1imPk6tJzWY7XizXleyhtp-vRv_C3B--qjAzoXUk3Gdw9vg2mKYTrEAJECiT5NVkcZRezKAaxmM97Mi';
    this.headers = new HttpHeaders().set('Authorization',autorisation);
   }
   fcmUrl = "https://fcm.googleapis.com/fcm/send";
   sendNotification(mydata:any){
    return this._http.post(this.fcmUrl,mydata,{ headers: this.headers });
  }
  //--------------------------------------------
}
