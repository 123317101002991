import { Component, OnInit } from '@angular/core';
import { StatService } from 'src/app/services/stat.service';
import { Statistique } from 'src/app/statistique';

@Component({
  selector: 'app-cp-stat',
  templateUrl: './cp-stat.component.html',
  styleUrls: ['./cp-stat.component.css']
})
export class CpStatComponent implements OnInit {
stat = new Statistique();
  constructor(private ss:StatService) { }

  ngOnInit(): void {
    this.getStatistique()
  }

  //------------get Stat----------------
  getStatistique(){
    this.ss.getStat().subscribe(
      res => this.stat = res,
      err => console.error(err),
      () => console.log('ok')
    );
  }
  //-----------------update--------------
  updateStat(){
    this.ss.updateStat(1,this.stat).subscribe(
      res => {console.log(res); this.getStatistique()}
    );
  }

}
