import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Statistique } from '../statistique';
import { HostService } from './host.service';

@Injectable({
  providedIn: 'root'
})
export class StatService {
  headers: HttpHeaders;
  constructor(private _http:HttpClient, private hostservice:HostService) {
    const token = localStorage.getItem('token');
    this.headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
   }
   host = this.hostservice.myhost;
  //---------------Get number pages---------------
  _url = this.host+"api/statistiques";
  getStat(){
    return this._http.get<Statistique>(this._url);
  }
  //-------------------Update-------------------
  updateStatUrl = this.host+"api/statistiques/";

  updateStat(id:number,st:Statistique){
    return this._http.put(this.updateStatUrl+id,st,{ headers: this.headers });
  }
}
