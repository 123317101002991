<h3>Publications</h3>
<div class="row" >
  <div class="col-md-6" *ngFor="let p of allpubs">
  <!-- --------------------------------------- -->

  <div class="card h-100">
    <div class="alert alert-info text-center h4" role="alert">
      {{p.titre}}
    </div>
    <div class="card-body">
    <div class="row">
      <div class="col-lg-3"><img class="img-fluid" src="{{srcpub}}{{p.image}}"></div>
      <div class="card-text col-lg-9" [innerHTML]="p.description"></div>
    </div>
    </div>
  </div>

  </div>
</div><!-- Fin row -->
