<div class="row">
  <span class="titre col-12"><i class="fas fa-chart-bar" style="margin-right: 10px;"></i>STATISTIQUES</span>
  <span class="col-12 st1">étudiants: {{stat.etudiants}}</span>
  <span class="col-12 st1">Enseignants: {{stat.profs}}</span>
  <span class="col-12 st1">Administartifs: {{stat.administratifs}}</span>
  <span class="col-12 st1">Départements: {{stat.departements}}</span>
  <span class="col-12 st1">Licences fondamentales: {{stat.lf}}</span>
  <span class="col-12 st1">Licences Professionnelles: {{stat.lp}}</span>
  <span class="col-12 st1">Licences éducatives: 3</span>
  <span class="col-12 st1">Masters de recherches: {{stat.master}}</span>
  <span class="col-12 st1">Masters spécialisés: {{stat.msp}}</span>
  <span class="col-12 st1">filières: {{stat.filieres}}</span>
  <span class="col-12 st1">Labos: {{stat.labos}}</span>
  <span class="col-12 st1">Formations doctorales: {{stat.fd}}</span>



</div>
