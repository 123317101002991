<ngx-spinner *ngIf="hideSpinner" bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#64d6e2" type = "square-jelly-box" [fullScreen] = "true"><p style="color: #64d6e2" > FLSHM Loading... </p></ngx-spinner>
      <div class="row">
        <div class="col-12 h3" style="color: blueviolet;">
          <div class="row">
          <div class="col-5">ACTUALITÉS</div>
          <div class="col-2"></div>
          <div class="col-5"></div>
        </div>
          <hr class="hr-habib">
        </div>
        <div class="col-lg-6 col-sm-12" *ngFor="let av of allAvis| paginate:mypagination; let i=index " style="margin-bottom: 10px;">

          <div class="card h-100">
            <img class="card-img-top shape" src="assets/img/card.jpg" alt="Card image cap">
            <div class="card-body">
              <h3 class="card-title" *ngIf="av.lang=='ar'" dir="rtl" style="text-align: right;" ><table style="text-align: right;"><tr><td><span class="badge badge-info">{{av.date}}</span></td></tr><tr><td>{{av.titre}}</td></tr></table></h3>
              <h3 class="card-title" *ngIf="av.lang=='fr'" dir="rtl" style="text-align: right;"><table><tr><td><span class="badge badge-info">{{av.date}}</span></td></tr><tr><td>{{av.titre}}</td></tr></table></h3>
              <hr class="hr-habib">
            <div *ngIf="av.image_only=='0'">
              <img *ngIf="av.image" src="{{srcimg}}{{av.image}}" class="img-thumbnail col-6">
              <div class="card-text" *ngIf="av.lang=='ar'" dir="rtl" [innerHTML]="av.msg"></div>
              <div class="card-text" *ngIf="av.lang=='fr'" dir="ltr" [innerHTML]="av.msg"></div>
            </div>
            <img *ngIf="av.image_only!='0'" src="{{srcimg}}{{av.image}}" class="img-thumbnail">
              <div class="card-footer text-muted">
              <!----------------------------->
              <hr>
              <div class="share-btn-container">
                <a  class="facebook-btn" (click)="fb()">
                  <i class="fab fa-facebook"></i>
                </a>

               <!-- <a href="#" class="twitter-btn">
                  <i class="fab fa-twitter"></i>
                </a>

                <a href="#" class="pinterest-btn">
                  <i class="fab fa-pinterest"></i>
                </a>-->

                <a  (click)="lnkd(av.titre)" class="linkedin-btn">
                  <i class="fab fa-linkedin"></i>
                </a>

                <a (click)="wtsp(av.titre)" class="whatsapp-btn">
                  <i class="fab fa-whatsapp"></i>
                </a>
              </div>
              <!--------------------------------------------------->
            </div>
            </div>
          </div>




        </div>
      </div>
      <div class="text-center"><pagination-controls
         (pageChange)="mypagination.currentPage = $event"
         responsive="true"
         previousLabel="Précédant"
         nextLabel="Suivant"
         screenReaderPaginationLabel="Pagination"
         screenReaderPageLabel="page"
         screenReaderCurrentLabel="You're on page"
          ></pagination-controls>
        </div>

