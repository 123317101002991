import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser'
import { Router } from '@angular/router';
import { Avis } from 'src/app/avis';
import { IAvis } from 'src/app/Iavis';
import { AuthService } from 'src/app/services/auth.service';
import { AvisService } from 'src/app/services/avis.service';

@Component({
  selector: 'app-cp',
  templateUrl: './cp.component.html',
  styleUrls: ['./cp.component.css']
})
export class CpComponent implements OnInit {
  msgdata='';
  photoname="Choisir une image";
  srcimg:string;
  avis = new Avis();

  constructor(private as:AvisService,
     private auth: AuthService, private sanitizer: DomSanitizer,
      private httpf:HttpClient,
      private router:Router) {
      }

  ngOnInit(): void {
    this.srcimg = this.as.host+"FLMLARA/storage/app/public/avis/";
    //this.srcimg = this.as.host+"storage/avis/"; //en local
    this.mesAvis();
    //this.mesFormatHtml();
  }
//------------------------------------
allAvis: IAvis[];
mesAvis(){
  this.as.getAllAvisCP().subscribe(
    data => {
      this.allAvis = data;
      this.allAvis.map(el => {el.msg = this.sanitizer.bypassSecurityTrustHtml(el.msg)})
      // bypassSecurityTrustHtml pour assurer l'insertion des style html
    },
    err => console.error(err),
    ()=>console.log('ok')
  )
}
//------------------------------------
selectedFile = null;
onFileSelected(event){
  this.selectedFile = event.target.files[0];
  this.photoname = this.selectedFile.name;
  //console.log(this.selectedFile);
}
//-----------------------------------
selectedFileUpdate = null;
onFileSelectedUpdate(event){
  this.selectedFileUpdate = event.target.files[0];
  //console.log(this.selectedFileUpdate);
}
//-----------------------------------

   addAvis(){
     //----nom original de l'image
    this.avis.image =this.selectedFile?.name;
    //---------------Affecter la date--------
    const today = new Date().toISOString().slice(0, 10)
    this.avis.date = today;
   //---- Détecter l'arabe-------------
    let arabic = /[\u0600-\u06FF]/;
    this.avis.lang =(arabic.test(this.avis.titre))?"ar":"fr";
   //--------------------------------


    this.as.setAvis(this.avis).subscribe(
      () => { this.mesAvis();},
      err => console.error(err),

    );

  if(this.selectedFile!=null){
    let fd = new FormData();
    fd.append('imageAvis',this.selectedFile,this.selectedFile.name);
    this.as.uploadAvisImage(fd).subscribe(
      //res => console.log(res),
      err => console.error(err)
    );
    this.selectedFile = null; // pour vider après l'envoie du fichier
    this.photoname="Choisir une image"; //bouton select file
  }
}
//------------------------------------
idDelete:number;
getIdToDelete(id:number){
this.idDelete = id;
}
supprimerAvis(){
  this.as.deletAvis(this.idDelete).subscribe(
    data => {/*console.log(data);*/ this.mesAvis();},
    err => console.error(err),
    () => console.log('ok')
  );
}
//----------------------------------
upAvis = new Avis();
chekPrio: Boolean;
chekImgOnly: Boolean;
showUpdateAvis(id:number){
  this.as.getAvis(id).subscribe(
    data => {
      this.upAvis = data;
      this.upAvis.image_only = Number(this.upAvis.image_only);
      this.upAvis.prio = Number(this.upAvis.prio);
    },
    err=>console.error(err)
  )
}
//----------------------------------
idupdate = this.upAvis.id;
modifAvis(id:number){
   this.as.updateAvis(id,this.upAvis).subscribe(
    () =>  { this.mesAvis();},
    err=>console.error(err)
  );
  //---------------------------------
  if(this.selectedFileUpdate!=null){
    let fd = new FormData();
    fd.append('imageUpdateAvis',this.selectedFileUpdate,this.selectedFileUpdate.name);
    fd.append('id',id.toString());
    this.as.updateUploadAvisImage(fd).subscribe(
      () => {this.mesAvis();},
      err => console.error(err)
    );
    this.selectedFileUpdate = null; // pour vider après l'envoie du fichier
  }
  //---------------------------------
}

//---------------------Logout---------
logout(){
  this.auth.signOut();
  this.router.navigate(['/mhslf-ng-x21']);
}
//-------------------------------------
}
