import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Publication } from 'src/app/publication';
import { PublicationService } from 'src/app/services/publication.service';

@Component({
  selector: 'app-cp-publication',
  templateUrl: './cp-publication.component.html',
  styleUrls: ['./cp-publication.component.css']
})
export class CpPublicationComponent implements OnInit {
  pub = new Publication();
  photoname="Choisir une image de couverture";
  srcpub:string="";
  verou=false;
  constructor(private sp:PublicationService,
              private sanitizer: DomSanitizer) { }


  ngOnInit(): void {
    this.srcpub = this.sp.host+"FLMLARA/storage/app/public/publications/";
    this.allPublications();
  }

  //------------------------------------
selectedFile = null;
onFileSelected(event){
  this.selectedFile = event.target.files[0];
  this.photoname = this.selectedFile.name;
  this.verou = true;
  //console.log(this.selectedFile);
}
//------------------------------------------------
addPub(){
  const nomImage = new Date().getTime()+'.jpg';
  this.pub.image = nomImage;

this.sp.setPublication(this.pub).subscribe(
  () => {this.allPublications();   },
  err => console.error(err),

);
if(this.selectedFile!=null){
  //---------envoi de slide
    let fd = new FormData();
    fd.append('nomImage',nomImage);
    fd.append('imagePub',this.selectedFile,this.selectedFile.name);

    this.sp.uploadPub(fd).subscribe(
      //res => console.log(res),
      err => console.error(err)
    );
    this.selectedFile = null; // pour vider après l'envoie du fichier
    this.verou = false; //verrouiller le boutton d'ajout
   }
}
//------------------------------------------------
//-----------------------------------------------
allpubs:Publication[];
allPublications(){
  this.sp.getAllPubs().subscribe(
    data => {this.allpubs = data;
    this.allpubs.map(el => {el.description = this.sanitizer.bypassSecurityTrustHtml(el.description)})
    //description doit être de type any
    // bypassSecurityTrustHtml pour assurer l'insertion des style html
    },
    err => console.error(err),
    //()=>console.log('ok Publications')
  );
}
//-------------------------------------------------
supprimerPublication(id){
this.sp.deletPub(id).subscribe(
  () => { this.allPublications();},
  err => console.error(err),

);
}
//----------------------------------------------

}
