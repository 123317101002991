<div class="container-fluid">
<div class="row">
  <div class="col-12 h3 text-info">Dernières vidéos
  <hr class="hr-habib">
</div>
  <div class="col-sl-3" *ngFor="let v of allVideo" style="margin-right: 10px;">
  <iframe  [src]="v.lien" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
</div>
</div>
