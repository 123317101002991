<cpmenu></cpmenu>
<div class="container">
  <form style="background-image: url('assets/img/bg.jpg'); padding: 10px;">

    <div class="form-group">
      <label class="h3">Titre Slide</label>
    <editor name="dd" [(ngModel)]="slide.titre"  apiKey="nxugqykf0aggo3yb2jkeack5ozx7fi7d0xwbgw6rjg9ygg97" [init]="{
      height: 200,
      menubar: ['table','insert'],
      plugins: [
        'advlist autolink lists link image charmap print',
        'preview anchor searchreplace visualblocks code',
        'fullscreen insertdatetime media table paste',
        'help wordcount','link','table'
      ],
      toolbar:
        'undo redo | formatselect | bold italic |fontsizeselect| \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent|forecolor backcolor |link| table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol|help'
    }"></editor>
      </div>

<div class="form-group">
  <div class="custom-file">
    <input type="file" (change)="onFileSelected($event)" class="custom-file-input" id="customFile" name="imageFile" required>
    <label class="custom-file-label" style="background: rgb(140, 77, 153); color: beige; font-weight: bold;" for="customFile">{{photoname}}</label>
  </div>


</div>
<div class="form-group col-6 offset-3">
  <button *ngIf="verou" class="btn btn-info col-12 " (click)="addSlide()">Ajouter</button>
</div>
  </form>

  <div class="row" *ngFor="let s of allslides; let i=index">
    <div class="col-8">
      <img src="{{srcslide}}{{s.image}}" class="img" alt="">
      <div class="card-text" [innerHTML]="s.titre"></div>
    </div>
    <div class="col-4">
      <button data-toggle="modal" data-target="#suppConfirm" (click)="getIdToDelete(s.id)" class="btn btn-danger"><i class="fa fa-trash-o fa-2x" aria-hidden="true"></i></button>
    </div>
  </div>

  <!---------------Modal suppression------------>
  <!-- The Modal -->
  <div class="modal" id="suppConfirm">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Suppression</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Voulez-vous vraiment supprimer cet Slide?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="supprimerSlide()" data-dismiss="modal">Ok</button>
          <button type="button" class="btn btn-secondary"  data-dismiss="modal">Annuler</button>
        </div>
      </div>
    </div>
  </div>
  <!---------------Fin modal supp-------------->

</div>
