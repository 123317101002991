<cpmenu></cpmenu>
<div class="container">
  <form style="background-image: url('assets/img/bg.jpg'); padding: 10px;">

    <div class="form-group">
      <label class="h3">Titre de la notification</label>
      <input style="background-color: blueviolet; color:cornsilk; font-size: 16pt;" type="text" class="form-control" name="titre" [(ngModel)]="myNotification.notification.title">
    </div>
    <div class="form-group">
      <label class="h3">Notification</label>
      <textarea style="background-color: blueviolet; color:cornsilk; font-size: 16pt;" class="form-control" name="body" [(ngModel)]="myNotification.notification.body"></textarea>
    </div>
 <div class="form-group">

  <div class="form-group col-6 offset-3">
    <button class="btn btn-info col-12 " (click)="envoyerNotification()">Envoyer</button>
  </div>

</div>

  </form>




  <!---------------Modal suppression------------>
  <!-- The Modal -->

  <!---------------Fin modal supp-------------->

</div>
