import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { User } from './../../user';
import { AuthService } from './../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
user = new User();
  constructor(private as:AuthService, private router:Router) { }

  ngOnInit(): void {
  }
  //-----------------------

  reponse   : any;
  token     : any;
  nomUser   : string="";
  LoginApp(){
    this.as.userLog(this.user).subscribe(
      data => {
        this.reponse = data;
        this.token   = this.reponse.token;
        this.nomUser = this.reponse.user.name;
        if(this.nomUser != ""){
          localStorage.setItem('token',this.token);
          localStorage.setItem('nom',this.nomUser);
          this.as.signIn();
          this.router.navigate(['/cp']);
        }
      },
        err => console.error(err),
        //()  => console.log('ok')
      );


  }
  //-------------------------

}
