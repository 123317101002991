<nav class="navbar navbar-expand-lg bg-info navbar-dark menu">
  <div class="container">
    <a class="navbar-brand" href="#"><i class="fas fa-home"></i></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item active">
          <a class="nav-link" href="#">FACULTE <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link">DÉPARTEMENTS</a>
        </li>
        <li class="nav-item">
          <a class="nav-link">fORMATIONS</a>
        </li>

        <li class="nav-item">
          <a class="nav-link" href="#">RECHERCHE & COOPERATION</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">ESPACE ETUDIANT</a>
        </li>



        <li class="nav-item">
          <a href="https://www.facebook.com/flshm.officielle" target="_blank"><img width="45px" src="assets/img/social/fb.png"></a>
        </li>
        <li class="nav-item">
          <a href="https://www.youtube.com/channel/UCww7k3TDhwVFMTaPrgoWIvA" target="_blank"><img width="45px" src="assets/img/social/yt.png"></a>
        </li>
        <li style="color: cornsilk;">
          <a class="nav-link">{{today}}</a>
        </li>


      </ul>
    </div>
  </div>
</nav>
