<cpmenu></cpmenu>
<div class="container">
  <form style="background-image: url('assets/img/bg.jpg'); padding: 10px;">

    <div class="form-group">
      <label class="h3">Nombre d'avis par page</label>
    <input type="naumber" class="form-control" name="nbr" [(ngModel)]="pagination.nombre_pages">
      </div>

<div class="form-group">

  <div class="form-group col-6 offset-3">
    <button class="btn btn-info col-12 " (click)="updatePage()">Modifier</button>
  </div>

</div>

  </form>
  <div class="row">
    <div class="col-12 h3 text-info">
      Nombre d'avis Par page: {{nbrPage}}
    </div>
  </div>



  <!---------------Modal suppression------------>
  <!-- The Modal -->

  <!---------------Fin modal supp-------------->

</div>
