import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { VideoService } from 'src/app/services/video.service';
import { Video } from 'src/app/video';

@Component({
  selector: 'app-cp-video',
  templateUrl: './cp-video.component.html',
  styleUrls: ['./cp-video.component.css']
})
export class CpVideoComponent implements OnInit {
video = new Video();
  constructor(private vs:VideoService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.mesVideo();
  }
  //----------------------------------
allVideo:Video[];
  mesVideo(){
    this.vs.getAllVideos().subscribe(
      data => {this.allVideo = data;
      this.allVideo.map(el=>{el.lien = this.sanitizer.bypassSecurityTrustResourceUrl(el.lien)})},
      err => console.error(err),
      ()=>console.log('ok')
    )
  }
  //------------------------------------
  addVideo(){
  this.vs.setVideo(this.video).subscribe(
    res => {console.log(res); this.mesVideo();},
  )
  }
  //-----------------------------------
  supprimerVideo(id){
this.vs.deletVideo(id).subscribe(
  res => {console.log('deleted'); this.mesVideo();},
)

  }


}
