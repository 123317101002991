<cpmenu></cpmenu>
<div class="container">
  <form style="background-image: url('assets/img/bg.jpg'); padding: 10px;">
    <div class="form-group">
      <label class="h3">Titre de la publication:</label>
      <input type="text" class="form-control" name="titre" [(ngModel)]="pub.titre" placeholder="Titre">
    </div>
    <div class="form-group">
      <label class="h3">Description:</label>
    <editor name="dd" [(ngModel)]="pub.description"  apiKey="nxugqykf0aggo3yb2jkeack5ozx7fi7d0xwbgw6rjg9ygg97" [init]="{
      height: 200,
      menubar: ['table','insert'],
      plugins: [
        'advlist autolink lists link image charmap print',
        'preview anchor searchreplace visualblocks code',
        'fullscreen insertdatetime media table paste',
        'help wordcount','link','table'
      ],
      toolbar:
        'undo redo | formatselect | bold italic |fontsizeselect| \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent|forecolor backcolor |link| table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol|help'
    }"></editor>
      </div>

<div class="form-group">
  <div class="custom-file">
    <input type="file" (change)="onFileSelected($event)" class="custom-file-input" id="customFile" name="imageFile" required>
    <label class="custom-file-label" style="background: rgb(140, 77, 153); color: beige; font-weight: bold;" for="customFile">{{photoname}}</label>
  </div>


</div>
<div class="form-group col-6 offset-3">
  <button *ngIf="verou" class="btn btn-info col-12 " (click)="addPub()">Ajouter</button>
</div>
  </form>

  <div class="row" >
    <div class="col-md-6" *ngFor="let p of allpubs; let i=index">
    <!-- --------------------------------------- -->

    <div class="card h-100">
      <div class="alert alert-info text-center h4" role="alert">
        {{p.titre}}
      </div>

      <div class="card-body">

      <div class="row">
        <div class="col-lg-3"><img class="img-fluid" src="{{srcpub}}{{p.image}}"></div>
        <div class="card-text col-lg-9" [innerHTML]="p.description"></div>
      </div>
      <div class="row">
        <div class="col-12">
          <button data-toggle="modal" [attr.data-target]="'#suppConfirm' + p.id"  class="btn btn-danger"><i class="fa fa-trash-o fa-2x" aria-hidden="true"></i></button>
        </div>
      </div>

      </div>
    </div>
    <!---------------Modal suppression------------>
  <!-- The Modal -->
  <div class="modal" id="suppConfirm{{p.id}}">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Suppression</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Voulez-vous vraiment supprimer la publication de {{p.titre}}?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="supprimerPublication(p.id)" data-dismiss="modal">Ok</button>
          <button type="button" class="btn btn-secondary"  data-dismiss="modal">Annuler</button>
        </div>
      </div>
    </div>
  </div>
  <!---------------Fin modal supp-------------->
    </div>
  </div><!-- Fin row -->


</div>
