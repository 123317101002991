import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { Utilisateur } from 'src/app/utilisateur';

@Component({
  selector: 'app-cp-user',
  templateUrl: './cp-user.component.html',
  styleUrls: ['./cp-user.component.css']
})
export class CpUserComponent implements OnInit {
utilisateur = new Utilisateur();
  constructor(private su:UsersService) { }

  ngOnInit(): void {
    this.allUsers();
  }
//----------------getAllUsers----------
users:Utilisateur[];
allUsers(){
  this.su.getAllUsers().subscribe(
    data => this.users = data
  )
}

//----------------------------------
  addUser(){
    this.su.setUtilisateur(this.utilisateur).subscribe(
      res => {console.log(res); this.allUsers();}
    )
  }
//-----------Delete user---------------
supprimerUser(id:number){
  this.su.deletUser(id).subscribe(
    res => {console.log('res'); this.allUsers();},
    err => console.error(err)
  )

}
//----------------------------------------------

}
