import { IAvis } from "./Iavis";

export class Avis implements IAvis {
  id: number;
  date:any;
  lang:string;
  titre:string;
  msg:any;
  prio:number = 0;
  categorie:number;
  image:string;
  image_only:number = 0;
  constructor(){}
}
