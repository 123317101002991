import { Component, OnInit } from '@angular/core';
import { Pagination } from 'src/app/pagination';
import { PaginationService } from 'src/app/services/pagination.service';

@Component({
  selector: 'app-cp-pagination',
  templateUrl: './cp-pagination.component.html',
  styleUrls: ['./cp-pagination.component.css']
})
export class CpPaginationComponent implements OnInit {
pagination = new Pagination();
  constructor(private ps:PaginationService) { }

  ngOnInit(): void {
    this.getPagination();
  }
  nbrPage:number;
  getPagination(){
this.ps.getNumberPages().subscribe(
  data => this.nbrPage = data.nombre_pages
);
  }

  updatePage(){
    this.ps.updatePagination(1,this.pagination).subscribe(
      () => { this.getPagination()}
    )

  }

}
