import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'cpmenu',
  templateUrl: './cp-menu.component.html',
  styleUrls: ['./cp-menu.component.css']
})
export class CpMenuComponent implements OnInit {
  afficheMenuUser = false;
  constructor(private auth:AuthService, private router:Router) { }

  ngOnInit(): void {
    if(localStorage.getItem('nom') === "Ayad"){
      this.afficheMenuUser = true;
    }

  }
  //---------------------Logout---------
logout(){
  this.auth.signOut();
  this.router.navigate(['/login']);
}

}
